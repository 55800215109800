import { DonateHospital } from './modules/donate/donate-hospital';
import { globalDef } from './modules/site-definitions';
import { getUrlParam } from './modules/utility';
import Cookies from 'js-cookie';

/**
 * Fetch nearest hospital 
 * @page all
 */

const donateHospital = new DonateHospital();
const hospital = await donateHospital.fetchNearestHospitalByIP();

if (hospital && hospital.length > 0) {
    Cookies.set('ip_hospital_id', hospital[0].hospitalId, {
        path: '/',
        domain: globalDef.mainDomain,
        secure: true,
        expires: 365
    });

    const hospitalSelect = document.querySelector<HTMLSelectElement>('#hospital');
    if (hospitalSelect) {
        hospitalSelect.value = hospital[0].hospitalId;

        // Update the hidden input element
        const hospitalInput = document.querySelector<HTMLInputElement>('[name="IpHospitalId"]');
        if (hospitalInput) {
            hospitalInput.value = hospital[0].hospitalId;
        }
    }
} 


/**
 * Set ecode cookie if query param exists. This should run on all pages
 * @page all
 */
const ecodeParam = getUrlParam('ecode') as string;

// Ecode is not set and user visits with a ecode query param.
if (ecodeParam) {
 Cookies.set('ecode', ecodeParam, {
   path: '/',
   domain: globalDef.mainDomain,
   secure: true,
   expires: 1
 });
}

/**
 * Set app campaign cookie if query param exists. This should run on all pages
 * @page all
 */
const appcampaignParam = getUrlParam('appcampaignid') as string;

// App campaign is not set and user visits with an app campaign query param.
if (appcampaignParam) {
    Cookies.set('app_campaign_id', appcampaignParam, {
        path: '/',
        domain: globalDef.mainDomain,
        secure: true,
        expires: 1
    });
}

/**
 * Set campaign cookie if query param exists. This should run on all pages
 * @page all
 */
const campaignParam = getUrlParam('campaignid') as string;

// Campaign is not set and user visits with a campaign query param.
if (campaignParam) {
    Cookies.set('campaign_id', campaignParam, {
        path: '/',
        domain: globalDef.mainDomain,
        secure: true,
        expires: 1
    });
}
